.App {
  text-align: center;
}
.CallNowBtn{
  background-color: #F8BE50;
  padding: 10px 20px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 3;
  border-radius: 10px;
  color: black;
  border: 1px solid;
  text-decoration: none;
  font-weight: 600;
  animation: callAnimation 1.5s infinite;
}
@keyframes callAnimation {
  0% {
    transform: translateY(5px);
    transition: .5s;
    /* transform: scale(1.1); */
  }
  50% {
    /* transform: translateY(08px); */
    transform: scale(1.1);transition: .5s;
  }
  100% {
    transform: translateY(5px);transition: .5s;
    /* transform: scale(1.1); */
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
