/* .slick-dots li{
    color:  red;
} */

/* .slick-active{
    color: red
} */

.demo{
    width: 98% !important;
    height: 300px;
    
   position: relative;
    margin-right: 1%;
    margin-left: 1%;
    
  
}
.demo button{
    position: absolute;
  bottom: 30px;
    left: 10px;
 
    font-size: 20px;
    padding: 10px 20px;
    color: white;
    background-color: #F8BE50;
    box-shadow: 2px 2px 08px  rgb(0, 0, 0);
    margin: 0px 20px;
    border: none;
}
.demo img{
    width: 100% ;
    
    height: 100%;
}