.Section3 {
  background-image: url("../../Images/AboutUsPic.png");
  height: 500px;
  width: 100%;
  background-size: 100% 100%;
  background-position: center;
}
.Outline {
  text-shadow: -1px -1px 0 rgb(97, 97, 97), 1px -1px 0 rgb(24, 24, 24),
    -1px 1px 0 #474747, 1px 1px 0 #3d3d3d;
}
.ContentContainerAboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: 0.5s linear;
  margin-left: 00%;
  color: black;
}
.ContentContainerAboutUs h3 {
  font-weight: bolder;
  text-align: center;
  font-size: 33px;
  text-transform: uppercase;
}
.ContentContainerAboutUs button {
  background-color: #f8be50;
  padding: 10px 20px;
  border: none;
  font-weight: 500;
  color: black;
  margin: 10px 15px;
  box-shadow: 3px 3px 06px black;
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 800px) {
  .ContentContainerAboutUs h3 {
    font-weight: bolder;
    font-size: 30px;
    text-transform: uppercase;
  }
  .Section3 {
    height: 500px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}
@media screen and (max-width: 500px) {
  .ContentContainerAboutUs h3 {
    font-weight: bolder;
    font-size: 21px;
    text-transform: uppercase;
  }
}
