.ContactUsContainer {
  background-image: url("../../Images/ContactUsBG.png");
  background-position: center;
}

.FormContainer {
  background-color: rgba(0, 0, 0, 0.658);
}

.ContactFormContainer{
    border: 1px solid white;
}
.inputFields {
  padding: 10px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.747);
  margin-top: 20px;
  border: none;
}
.inputFieldsTextAresContactUs {
  margin-top: 20px;
  padding: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.74);
}
.FormContainer input[type="submit"]{
    background-color: #F8BE50;
    padding: 10px 20px; border: none;
    font-weight: 500;
    color: black;
    margin: 10px 0px;
    box-shadow: 0px 3px 06px black;;
}
.inputFields:nth-child(2) {
  width: 49%;
}
.inputFields:nth-child(3) {
  width: 49%;
  margin-left: 2%;
}
.ContactInfo {
  background-color: rgba(255, 255, 255, 0.74);
}
