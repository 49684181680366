.modalContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.562);
  z-index: 4;
}
.FormContainer2 {
  width: 70vw;
  height: 500px;
  background-color: white;
  display: flex;
  overflow: hidden;
}
.FormContainer2 div:nth-child(1) {
  background-color: rgb(255, 255, 255);
  width: 70%;
}
.FormContainer2 div:nth-child(1) input[type="submit"] {
  background-color: #f8be50;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  font-weight: 600;
  box-shadow: 3px 3px 010px black;
}
.FormContainer2 div:nth-child(2) {
  background-color: #f8be50;
  width: 30%;

  position: relative;
}
.FormContainer2 div:nth-child(2) button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px !important;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.842);
}
::placeholder {
  color: rgb(0, 0, 0);
  font-size: 20px;
}
.FormContainer2 .inputFields {
  background-color: rgba(0, 0, 0, 0.144);
  font-size: 20px;
  color: black;
}

.FormContainer2 .inputFieldsTextAres {
  background-color: rgba(0, 0, 0, 0.144);
  font-size: 20px;
  color: black;
  margin-top: 20px;
  border: none;
  padding: 10px;
}
.FormContainer2 div:nth-child(2) div {
  margin-top: 100px;
  padding: 10px 30px;
  width: 100%;
}

@media screen and (max-height: 550px) {
  .FormContainer2 {
    width: 70vw;

    height: 450px;
    overflow: scroll;

    display: flex;
  }
  .FormContainer2 div:nth-child(1) {
    width: 75%;
  }
  .FormContainer2 div:nth-child(2) {
    background-color: #f8be50;
    width: 25%;
    height: 100%;
    position: relative;
  }
}
@media screen and (max-width: 1090px) {
  .FormContainer2 div:nth-child(1) {
    background-color: white;
    width: 100%;
  }
  .FormContainer2 div:nth-child(2) {
    background-color: #f8be50;
    width: 0%;

    position: relative;
  }
}
@media screen and (max-width: 600px) {
  ::placeholder {
    color: rgb(0, 0, 0);
    font-size: 18px;
  }
  .FormContainer2 .inputFields,
  .inputFieldsTextAres {
    background-color: rgba(0, 0, 0, 0.144) !important;
    font-size: 18px;
    color: black;
  }
}
